import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub';
import Card from '@/components/Card';
import { Tab } from '@/components/UI';
//import echarts from 'echarts';

//引入编译信息
import versionJSON from '@/utils/versionJSON.json';
import { parseTime } from '@/public/methods';
import store from '@/store';
//console.log(echarts);

import FetchData from '@/components/FetchData';

//引入图表组件
//import VeLine from 'v-charts/lib/line'; //折线图
//import VeBar from 'v-charts/lib/bar'; //条形图
//import VeHistogram from 'v-charts/lib/histogram'; //柱状图
//import VePie from 'v-charts/lib/pie'; //饼图
//import VeRing from 'v-charts/lib/ring'; //环图

const VeLine = () => import(/*webpackChunkName:'chunk-ve-line'*/ 'v-charts/lib/line');
const VeBar = () => import(/*webpackChunkName:'chunk-ve-bar'*/ 'v-charts/lib/bar');
const VeHistogram = () => import(/*webpackChunkName:'chunk-ve-histogram'*/ 'v-charts/lib/histogram');
const VePie = () => import(/*webpackChunkName:'chunk-ve-pie'*/ 'v-charts/lib/pie');
const VeRing = () => import(/*webpackChunkName:'chunk-ve-ring'*/ 'v-charts/lib/ring');
/*
const versionJSON = import(/*webpackChunkName:'_utils-version-j-s-o-n'*\/ '@/utils/versionJSON.json').catch(() => {
	//
});
*/

interface VersionJSON {
	compileTime: number;
}

export default defineComponent({
	components: {
		PageNodeMain,
		PageNodeSub,
		Card,
		Tab,
		VeLine,
		VeBar,
		VeHistogram,
		VePie,
		VeRing,
		FetchData,
	},
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		//站点概况部分
		const countData = reactive([
			{
				key: 'phrase',
				text: '说说',
				count: -1,
				todayAddCount: 0,
			},
			{
				key: 'blogArticle',
				text: '文章',
				count: -1,
				todayAddCount: 0,
			},
			{
				key: 'photo',
				text: '相册',
				count: -1,
				todayAddCount: 0,
			},
			{
				key: 'visitor',
				text: '访客',
				count: -1,
				todayAddCount: 0,
			},
		]);
		watch(
			() => store.state.data.all,
			newValue => {
				//console.log(newValue);
				const oCount = (newValue as any)?.appInfo?.count;
				if (oCount && typeof oCount === 'object') {
					countData.forEach((item, index) => {
						if (typeof oCount[item.key] === 'number') {
							countData[index].count = oCount[item.key];
						}
					});
				}
			},
			{ immediate: true, deep: true }
		);
		//图表数据部分
		//chartData作废
		/*
		const chartData = reactive({
			columns: ['日期', '访客', '点赞', '评论', '分享'],
			rows: [
				{
					日期: '11.19',
					访客: 1416,
					点赞: 553,
					评论: 288,
					分享: 299,
				},
				{
					日期: '11.20',
					访客: 1393,
					点赞: 476,
					评论: 188,
					分享: 111,
				},
				{
					日期: '11.21',
					访客: 1518,
					点赞: 772,
					评论: 521,
					分享: 50,
				},
				{
					日期: '11.22',
					访客: 2048,
					点赞: 538,
					评论: 333,
					分享: 88,
				},
				{
					日期: '11.23',
					访客: 1122,
					点赞: 666,
					评论: 85,
					分享: 40,
				},
				{
					日期: '11.24',
					访客: 1416,
					点赞: 444,
					评论: 63,
					分享: 2,
				},
				{
					日期: '11.25',
					访客: 1281,
					点赞: 538,
					评论: 322,
					分享: 30,
				},
			],
		//});
		*/
		const chartSettings = {
			area: true,
		};
		onMounted(() => {
			/*
            const myChart = (echarts as any).init(
                document.getElementById('charts')
            );
            const option = {
                title: {
                    text: 'ECharts 入门示例',
                },
                tooltip: {},
                legend: {
                    data: ['销量'],
                },
                xAxis: {
                    data: [
                        '衬衫',
                        '羊毛衫',
                        '雪纺衫',
                        '裤子',
                        '高跟鞋',
                        '袜子',
                    ],
                },
                yAxis: {},
                series: [
                    {
                        name: '销量',
                        type: 'bar',
                        data: [5, 20, 36, 10, 10, 20],
                    },
                ],
            };

            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
            */
		});
		//导航栏
		const tabList = ref([
			{
				key: 'default',
				title: '访客统计',
				action: () => {
					tabActiveKey.value = 'default';
				},
				isActive: true,
			},

			{
				key: 'blogcategory',
				title: '文章分类',
				action: () => {
					tabActiveKey.value = 'blogcategory';
				},
				isActive: false,
			},

			{
				key: 'payload',
				title: '服务器负载',
				action: () => {
					return new Promise((resolve, reject) => {
						setTimeout(() => {
							tabActiveKey.value = 'payload';
							resolve('ok');
						}, 200);
					});
				},
				isActive: false,
			},
		]);
		const tabActiveKey = ref('default');
		//图表数据：访客统计
		const dataDefault: Ref<any> = ref({ columns: [], rows: [] });
		const touchDataDefault = (data: any) => {
			//console.log('获取数据', data);
			dataDefault.value.rows = data;
			dataDefault.value.columns = data[0] && typeof data[0] === 'object' ? Object.keys(data[0]) : [];
			//console.log('处理后的键值', dataDefault.value.columns);
			//console.log('处理后的数据', dataDefault.value);
		};
		//图表数据：文章分类
		const dataBlogcategory: Ref<any> = ref({ columns: [], rows: [] });
		const touchDataBlogcategory = (data: any) => {
			dataBlogcategory.value.rows = data;
			dataBlogcategory.value.columns = ['key', 'count']; //data[0] && typeof data[0] === 'object' ? Object.keys([data[0]]) : [];
			//console.log(dataBlogcategory.value);
		};
		//图表数据：服务器负载
		const dataPayload = ref({});
		const touchDataPayload = (data: any) => {
			dataPayload.value = data;
		};

		const chartData = computed(() => {
			return {
				columns: ['日期', '访客', '点赞', '评论', '分享'],
				rows: [
					{
						日期: '11.19',
						访客: 1234,
						点赞: 553,
						评论: 288,
						分享: 299,
					},
					{
						日期: '11.20',
						访客: 1416,
						点赞: 553,
						评论: 288,
						分享: 299,
					},
				],
			};
		});

		//相关链接
		const relatedLinks: Ref<any> = ref([
			{
				key: 'admin',
				title: '网站后台管理',
				url: '/admin/',
			},
			{
				key: 'aliyun',
				title: '阿里云控制台',
				url: 'https://www.aliyun.com/',
			},
			{
				key: 'baiduStatistics',
				title: '百度统计',
				url: 'https://tongji.baidu.com/',
			},
			{
				key: 'chinaz',
				title: '站长之家',
				url: 'https://www.chinaz.com/',
			},
		]);
		//开发者信息
		//console.log(versionJSON);
		interface VersionJson {
			compileTime: number;
			projectName: string;
			projectVersion: string;
			projectAuthor: string;
		}
		const developerInfo = reactive({
			client: {
				projectName: versionJSON && (versionJSON as any).projectName ? (versionJSON as any).projectName : 'sheaneh',
				version: versionJSON && (versionJSON as any).projectVersion ? (versionJSON as any).projectVersion : '0.0.1',
				author: versionJSON && (versionJSON as any).projectAuthor ? (versionJSON as any).projectAuthor : '仙娥',
				useCode: ['Rust', 'TypeScript', 'JavaScript', 'VBScript', 'ActionScript'],
				framework: ['Yew', 'React', 'Vue'],
				usePackage: ['react-draft', 'ruffle-flash-player', 'sheaneh-ui'],
				lastBuildTime: versionJSON && (versionJSON as any).compileTime ? parseTime((versionJSON as any).compileTime / 1000) : ' [未知]',
				lastUpdateTime: (() => {
					let res = '';
					try {
						const oSfso = new ActiveXObject('Scripting.FileSystemObject');
						const fileObj = oSfso.GetFile('/manifest.json');
						const modiTime = fileObj.DateLastModified;
						res = `${new Date(modiTime)}`;
					} catch (e) {
						res = '[仅IE可读取]'; //'[IE <= 10.0 可见]';
					}
					return res;
				})(), //'[仅IE浏览器可见]',
			},
			server: {
				projectName: '仙娥小站',
				version: '0.0.1',
				author: '仙娥',
				engine: 'Apache',
				useCode: 'PHP',
				framework: 'ThinkPHP',
				renderFormat: 'HTML',
				interfaceFormat: 'XML JSON',
				lastUpdateTime: (() => {
					let res = '';
					try {
						const oMeta = document.querySelector('meta[name="server-last-upadte-time"]')!;
						const sTime = oMeta.getAttribute('content');
						const nTime = Number(sTime);
						if (isNaN(nTime)) {
							throw '';
						}
						res = parseTime(nTime);
					} catch (e) {
						res = '[无法获取]';
					}
					return res;
				})(),
			},
		});
		return {
			countData,
			chartData,
			chartSettings,
			tabList,
			tabActiveKey,
			developerInfo,
			relatedLinks,
			t,
			//数据
			dataDefault,
			touchDataDefault,
			dataBlogcategory,
			touchDataBlogcategory,
			dataPayload,
			touchDataPayload,
		};
	},
});
